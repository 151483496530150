import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import backIcon from 'images/icons/arrow-left.svg';
import DirectoriesView from './DirectoriesView';
import actions from 'actions/OneDrive/OneDrivePreviewActions';

class OneDrivePreviewPage extends Component {
  componentDidMount() {
    const { dispatch, params } = this.props;
    const legalHoldId = params?.id;
    dispatch(actions.doGetDirectoriesHierarchy(legalHoldId));
  }

  render() {
    const { navigate } = this.props;
    return (
      <div>
        <Widget>
          <div className={'d-flex mb-4 align-items-center'}>
            <button className={'btn bg-transparent me-2 ps-0'} onClick={() => navigate(-1)}>
              <img src={backIcon} alt={'arrow-back'} />
            </button>
            <h4 className={`mb-1 first-headline-text d-flex align-items-center mt-1`}>Back</h4>
          </div>
          <Row sm={12} style={{ height: '100vh' }}>
            <Col sm={3}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3'>Directories</h3>
                <div>
                  <DirectoriesView
                    nodes={this.props?.directoriesList}
                    dispatch={this.props.dispatch}
                  />
                </div>
              </Widget>
            </Col>
            <Col sm={4}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center mb-3'>File List</h3>
                <div className='ms-3'>
                  {this.props?.filesList?.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              </Widget>
            </Col>
            <Col sm={5}>
              <Widget style={{ height: '100vh' }}>
                <h3 className='text-center'>Preview</h3>
                <div>
                  <p>File preview will appear here</p>
                </div>
              </Widget>
            </Col>
          </Row>
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    directoriesList: store.OneDrive.directoriesList,
    filesList: store.OneDrive.filesList
  };
}

export default withRouter(connect(mapStateToProps)(OneDrivePreviewPage));
