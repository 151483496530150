import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/LegalHold/LegalHoldListActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import actionsLC from 'actions/LegalCase/LegalCaseFormActions';
import plusIcon from 'images/icons/plus.svg';
import viewIcon from 'images/icons/eye.svg';
import syncIcon from 'images/icons/sync.svg';
import closeIcon from 'images/icons/close.svg';
import trashIcon from 'images/icons/trash.svg';
import { FormattingService } from 'utils/sizeFormatting';
import formActions from 'actions/LegalHold/LegalHoldFormActions';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as searchDataFormat from 'components/CRUD/Search/list/SearchDataFormatters';
import { customCloseBtn } from 'actions/common';
import { VscOpenPreview } from 'react-icons/vsc';
import LegalHoldSlackFormPage from 'components/CRUD/LegalHold/form/slackForm/LegalHoldSlackFormPage';
import { GiConfirmed } from 'react-icons/gi';
import chatListStyle from './ChatLegalHoldList.module.scss';
import { CgProfile } from 'react-icons/cg';
import { IoWarning } from 'react-icons/io5';

class ChatLegalHoldTilesView extends Component {
  intervalID = 0;
  interval = 0;
  state = {
    modalOpen: false,
    resetModalOpen: false,
    isIndexing: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 3,
    newRows: [],
    searchString: '',
    isEditing: false,
    legalHoldId: '',
    openDeleteModal: false,
    isNotConfirmed: false,
    openModalFromView: false,
    expandExport: false
  };

  handleIndexing() {
    const { dispatch, legalCaseId } = this.props;
    const legalHoldId = this.state.cell;
    const currentUser = this.props?.currentUser;
    const isChat = true;
    dispatch(actions.doAllowIndexing(legalHoldId, currentUser, isChat)).then(() => {
      this.setState({
        modalOpen: false
      });
      dispatch(
        actions.doFetchChatLegalHolds({
          searchString: this.state.searchString,
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.chatCount,
          newRows: this.props.chatLegalHoldsList
        });
      });
    });
  }

  resetQueuedItems() {
    const { dispatch, legalCaseId } = this.props;
    const legalHoldId = this.state.cell;
    const currentUser = this.props?.currentUser;
    const isChat = true;
    dispatch(actions.doResetQueuedItems(legalHoldId, currentUser, isChat)).then(() => {
      this.setState({
        resetModalOpen: false
      });
      dispatch(
        actions.doFetchChatLegalHolds({
          searchString: this.state.searchString,
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.chatCount,
          newRows: this.props.chatLegalHoldsList
        });
      });
    });
  }

  closeModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  openChatModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenChatLH());
  }

  closeChatModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseChatLH());
  }

  handleViewLegalHold = async (row) => {
    const { navigate } = this.props;
    const legalHoldId = row?.legalHoldId;
    if (!row?.legalHoldConfirmed) {
      navigate(`/app/rsmfViewer?legalHoldId=${legalHoldId}`);
    } else {
      navigate(`/app/LegalHold/${legalHoldId}`);
    }
  };

  confirmChatLegalHold = (item) => {
    const { dispatch } = this.props;
    const isConfirmed = true;
    const legalHoldId = item?.legalHoldId;
    dispatch(formActions.doConfirmDiscardChatLH(legalHoldId, isConfirmed)).then(() => {
      dispatch(
        actions.doFetchChatLegalHolds({
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.chatCount,
          newRows: this.props.chatLegalHoldsList
        });
      });
    });
  };

  discardChatLegalHold = (item) => {
    const { dispatch, legalCaseId } = this.props;
    const legalHoldId = item?.legalHoldId;
    const isConfirmed = false;
    dispatch(formActions.doConfirmDiscardChatLH(legalHoldId, isConfirmed)).then(() => {
      dispatch(
        actions.doFetchChatLegalHolds({
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.chatCount,
          newRows: this.props.chatLegalHoldsList
        });
      });
    });
  };

  actionFormatter(cell, row) {
    const { navigate, permissions, caseStatus } = this.props;
    return (
      <div className='d-flex justify-content-end align-items-center w-100'>
        {/* Index,Retry failed and Delete buttons will be hidden until functionality is added from BE */}

        {/* {permissions['LegalUI.LegalHold.Index'] &&
          caseStatus !== config.caseStatus.Closed &&
          !row.isChatLegalHold && (
            <p
              style={{
                display:
                  !row.legalHoldStatistics ||
                  row.legalHoldStatistics?.itemCount === 0 ||
                  row.indexingQueued ||
                  row.isIndexed ||
                  !row.legalHoldConfirmed ||
                  (this.props.legalCase?.restoreInProgress &&
                    !this.props.legalCase?.restoreComplete)
                    ? 'none'
                    : 'block',
                marginBottom: '0px'
              }}
            >
              <button
                className={`btn text-white first-body-text d-flex align-items-center me-3`}
                type='button'
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    cell,
                    isIndexing: [...this.state.isIndexing, cell]
                  });
                }}
              >
                <img
                  src={syncIcon}
                  alt={'sync'}
                  width={'16px'}
                  height={'16px'}
                  className={'me-2'}
                />
                <p className={'mb-0'}>Index</p>
              </button>
            </p>
          )} */}
        {/* {permissions['LegalUI.LegalHold'] &&
          caseStatus !== config.caseStatus.Closed &&
          !row.isChatLegalHold && (
            <p
              style={{
                display: !row.indexingQueued || !row.legalHoldConfirmed ? 'none' : 'block',
                marginBottom: '0px'
              }}
            >
              <button
                className={`btn text-white first-body-text d-flex align-items-center me-3`}
                type='button'
                onClick={() => {
                  this.setState({
                    resetModalOpen: true,
                    cell,
                    isIndexing: [...this.state.isIndexing, cell]
                  });
                }}
              >
                <img
                  src={closeIcon}
                  alt={'sync'}
                  width={'16px'}
                  height={'16px'}
                  className={'me-2'}
                />
                <p className={'mb-0'}>Retry failed</p>
              </button>
            </p>
          )} */}
        {/* {row?.legalHoldStatistics?.itemCount === 0 &&
          row?.legalHoldStatistics?.isResultValid &&
          row.legalHoldConfirmed &&
          caseStatus !== config.caseStatus.Closed && (
            <button
              className={`btn text-white first-body-text d-flex align-items-center me-3`}
              type='button'
              onClick={() => {
                this.setState({
                  openDeleteModal: true,
                  legalHoldId: cell
                });
              }}
            >
              <img src={trashIcon} alt={'sync'} width={'14px'} height={'14px'} className={'me-2'} />
              <p className={'mb-0'}>Delete</p>
            </button>
          )} */}
        {permissions['LegalUI.LegalHold'] && row.isChatLegalHold && (
          <button
            className='btn text-white first-body-text d-flex align-items-center me-3'
            type='button'
            onClick={() => navigate(`/app/rsmfViewer?legalHoldId=${row.legalHoldId}`)}
          >
            <VscOpenPreview
              style={{
                fontWeight: 'bold',
                width: '16px',
                height: '16px'
              }}
            />
            <p className={'mb-0 ms-2'}>Preview</p>
          </button>
        )}
        {permissions['LegalUI.LegalHold'] && (
          <button
            className='btn text-white first-body-text d-flex align-items-center me-2'
            type='button'
            onClick={() => this.handleViewLegalHold(row)}
          >
            <img src={viewIcon} alt={'plus'} className={'me-2'} />
            <p className={'mb-0'}>Details</p>
          </button>
        )}
      </div>
    );
  }

  refreshTable = () => {
    const { dispatch, navigate } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(actionsLC.doGetStats(this.props.legalCaseId, navigate));
      dispatch(
        actions.doFetchChatLegalHolds({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.chatCount,
          newRows: this.props.chatLegalHoldsList
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetchChatLegalHolds({
        searchString: this.state.searchString,
        legalCaseId: this.props.legalCaseId,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.chatCount,
        newRows: this.props.chatLegalHoldsList
      });
    });
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetchChatLegalHolds({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props.chatLegalHoldsList,
          totalCount: this.props.chatCount
        });
      });
    }
    if (prevProps.chatLegalHoldsList !== this.props.chatLegalHoldsList) {
      this.setState({
        newRows: this.props.chatLegalHoldsList,
        totalCount: this.props.chatCount
      });
    }
    if (
      prevProps.legalHoldStats !== this.props.legalHoldStats &&
      this.props.legalHoldStats?.isResultValid
    ) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetchChatLegalHolds({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        newRows: this.props.chatLegalHoldsList,
        totalCount: this.props.chatCount
      });
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleDeleteLegalHold = () => {
    const { dispatch } = this.props;
    const filter = {
      legalCaseId: this.props?.legalCaseId,
      searchString: this.state.searchString,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize
    };
    const isChat = true;
    dispatch(
      actions.doDelete(this.state.legalHoldId, filter, this.props?.currentUser, isChat)
    ).then(() => {
      this.setState({
        openDeleteModal: false
      });
    });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  showTable() {
    const { dispatch } = this.props;
    dispatch(actions.doShowTable());
  }

  columnsToExport = [
    'legalHoldName',
    'legalHoldDescription',
    'creationDate',
    'itemSizeInBytes',
    'itemCount',
    'finallyFailedItemsCount',
    'legalHoldConfirmed'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/Chat/ExportLegalHolds?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/Chat/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };
  render() {
    const toReadableSizeFormat = new FormattingService();
    return (
      <div>
        <Row>
          <Col sm={12}>
            <>
              <Row key={'table-part'}>
                <Col style={{ marginTop: '8px' }}>
                  <div className={'d-flex align-items-center'}>
                    {this.props.permissions['LegalUI.LegalHold.ChatLegalHold'] &&
                      this.props?.caseStatus !== config.caseStatus.Closed && (
                        <button
                          className='btn first-body-text d-flex align-items-center'
                          type='button'
                          onClick={() => this.openChatModal()}
                        >
                          <img src={plusIcon} alt={'plus'} className={'me-2'} />
                          <p className={'mb-0'}>New chat</p>
                        </button>
                      )}
                  </div>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5px'
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                  <span>
                    <button
                      className='btn fw-semi-bold d-flex align-items-center'
                      type='button'
                      onClick={() => this.showTable()}
                      style={{ height: '37px', marginRight: '5px' }}
                    >
                      <FontAwesomeIcon icon={faTable} />
                    </button>
                  </span>
                  <span>
                    <input
                      type='search'
                      placeholder={'Search'}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleSearch();
                        }
                      }}
                      style={{
                        border: '0.5px solid #0046b1',
                        borderRadius: '3px'
                      }}
                      className={'form-control search-input me-5 w-200'}
                      value={this.state.searchString}
                      onChange={(e) => {
                        this.setState({
                          searchString: e.target.value
                        });
                      }}
                    />
                  </span>
                  <span>
                    <button
                      className='btn search-button-with-icon me-2 ms-10'
                      type='button'
                      onClick={this.handleSearch}
                      style={{ height: '37px', width: '37px' }}
                    >
                      <img
                        title={'search'}
                        alt={'search'}
                        width={14}
                        height={14}
                        src={searchIcon}
                      />
                    </button>
                  </span>
                </Col>
              </Row>
            </>
          </Col>
        </Row>
        <Row sm={12} className={`mt-4 ${chatListStyle.widgetRow}`}>
          {this.state.newRows?.length ? (
            this.state.newRows?.map((item) => (
              <Col sm={4} key={item.legalHoldId} className={chatListStyle.widget}>
                <Widget className={chatListStyle.chatWidget}>
                  <div className={chatListStyle.cardHeaderContainer}>
                    <div>
                      <p className={`${chatListStyle.cardHeader} first-headline-text`}>
                        {item.legalHoldName}
                      </p>
                      <p className='d-flex'>
                        <span className={`${chatListStyle.resultItem} first-subtitle-text`}>
                          Description:
                        </span>
                        <p style={{ fontSize: '14px', marginTop: '2px' }}>
                          {item.legalHoldDescription}
                        </p>
                      </p>
                    </div>
                    {item.legalHoldConfirmed ? (
                      <p className={chatListStyle.headerIcon}>
                        <GiConfirmed className={chatListStyle.confirmIcon} />
                      </p>
                    ) : (
                      <p className={styles.confirmationBtn}>
                        {!item.legalHoldConfirmed &&
                          item?.legalHoldStatistics !== null &&
                          this.props.caseStatus !== config.caseStatus.Closed && (
                            <button
                              className='btn text-white first-body-text d-flex align-items-center mb-2 ms-2'
                              type='button'
                              onClick={() => this.confirmChatLegalHold(item)}
                              disabled={!item?.legalHoldStatistics?.isResultValid}
                            >
                              <p className={'mb-0'}>Confirm</p>
                            </button>
                          )}
                        {!item.legalHoldConfirmed &&
                          item?.legalHoldStatistics !== null &&
                          this.props.caseStatus !== config.caseStatus.Closed && (
                            <button
                              className='btn text-white first-body-text d-flex align-items-center ms-2'
                              type='button'
                              onClick={() => this.discardChatLegalHold(item)}
                              disabled={!item?.legalHoldStatistics?.isResultValid}
                            >
                              <p className={'mb-0'}>Discard</p>
                            </button>
                          )}
                      </p>
                    )}
                  </div>
                  <div className='w-100'>
                    <p className={`${chatListStyle.subHeaders} first-subtitle-text`}>Filter by</p>
                    <p className='mb-0 d-flex'>
                      <p className={`${chatListStyle.resultItem} first-subtitle-text mb-0`}>
                        Date:
                      </p>
                      <p className='mb-0'>
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateFrom)}
                        </span>
                        {' - '}
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateTo)}
                        </span>
                      </p>
                    </p>
                    <p className='mb-0 d-flex'>
                      <span className={`${chatListStyle.resultItem} first-subtitle-text`}>
                        Channels:
                      </span>
                      {item?.channelInfo ? (
                        <span className={chatListStyle.channelsList}>
                          {item?.channelInfo?.map((element, index) => (
                            <span key={index} style={{ margin: '3px' }}>
                              {element.channelName}
                              {index + 1 < item?.channelInfo?.length && <span>,</span>}
                            </span>
                          ))}
                        </span>
                      ) : (
                        <span className={chatListStyle.channelsList}>Any</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <p className={`${chatListStyle.subHeaders} first-subtitle-text mt-3`}>
                      Results
                    </p>
                    <div className='w-100 d-flex mb-0 first-subtitle-text'>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Channels:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.totalChannels
                            ? `${toReadableSizeFormat.numberWithCommas(item?.totalChannels)}`
                            : 0}
                        </p>
                      </p>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Messages:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.totalMessages
                            ? `${toReadableSizeFormat.numberWithCommas(item.totalMessages)}`
                            : 0}
                        </p>
                      </p>
                    </div>
                    <div className='w-100 d-flex mb-0 first-subtitle-text'>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Attachments:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {item?.totalAttachments
                            ? `${toReadableSizeFormat.numberWithCommas(item?.totalAttachments)}`
                            : 0}
                        </p>
                      </p>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Size:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {' '}
                          {item?.legalHoldStatistics?.itemSizeInBytes
                            ? `${dataFormat.sizeFormatter(
                                item.legalHoldStatistics.itemSizeInBytes
                              )}`
                            : '0.00 B'}
                        </p>
                      </p>
                    </div>
                    {/* // Will be uncommented when Index functionality is added */}
                    {/* <div className='w-100 d-flex mb-0 first-subtitle-text'>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Items Indexed:</p>
                        <p className='first-body-text mt-1 mb-0 me-1'>
                          {' '}
                          {item?.legalHoldStatistics?.indexedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item?.legalHoldStatistics?.indexedItemsCount
                              )}`
                            : 0}
                        </p>
                      </p>
                      <p className='w-50 d-flex mb-0'>
                        <p className={chatListStyle.resultItem}>Index Failures:</p>
                        <p className='first-body-text mt-1 mb-0'>
                          {' '}
                          {item?.legalHoldStatistics?.finallyFailedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item?.legalHoldStatistics.finallyFailedItemsCount
                              )}`
                            : 0}
                        </p>
                      </p>
                    </div> */}
                  </div>
                  <p className='mt-4'>
                    <span className='first-subtitle-text me-2'>
                      <CgProfile className={chatListStyle.confirmIcon} />
                    </span>
                    <span className='me-1 first-body-text'>{item?.expireonUser?.firstName}</span>
                    <span className='me-1 first-body-text'>{item?.expireonUser?.lastName}</span>
                    <span className='first-body-text'>
                      ({dataFormat.dateTimeFormatter(item.creationDate)})
                    </span>
                  </p>
                  <p className='d-flex justify-content-center align-items-center mb-0'>
                    {this.actionFormatter(item.legalHoldId, item)}
                  </p>
                </Widget>
              </Col>
            ))
          ) : (
            <div
              style={{ height: this.state.newRows?.length ? 500 : 'auto' }}
              className={'d-flex w-100 justify-content-center align-items-center'}
            >
              <p className={'text-white'}>No data available</p>
            </div>
          )}
        </Row>
        <Row>
          <Col style={{ marginTop: '3px' }}>
            <p>Total: {this.state.totalCount}</p>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.state.totalCount ? (
              <Pagination
                totalItemsCount={this.state.totalCount}
                onChange={this.handlePageChange}
                activePage={this.state.pageNumber}
                itemsCountPerPage={this.state.pageSize}
                pageRangeDisplayed={5}
                prevPageText={'<'}
                nextPageText={'>'}
                firstPageText={'<<'}
                lastPageText={'>>'}
                linkClassFirst={styles.paginationNext}
                linkClassPrev={styles.paginationNext}
                linkClassNext={styles.paginationNext}
                linkClassLast={styles.paginationNext}
                linkClass={styles.pageLink}
                activeLinkClass={styles.activeLinkClass}
              />
            ) : null}
            <Dropdown
              isOpen={this.state.paginationModalOpen}
              toggle={() =>
                this.setState({
                  paginationModalOpen: !this.state.paginationModalOpen
                })
              }
              className={styles.pageSizeDropdown}
            >
              <DropdownToggle className='bg-transparent text-white d-flex'>
                <p style={{ marginBottom: '3px', marginRight: '2px' }} className='first-body-text'>
                  {this.state.pageSize}
                </p>
                <img
                  src={caretDown}
                  alt={'uncollapsed'}
                  width='20px'
                  height='20px'
                  className='ms-2'
                />
              </DropdownToggle>
              <DropdownMenu className='dropdown-position-fixed'>
                {[3, 6, 9].map((item) => (
                  <DropdownItem
                    className={styles.dropdownItem}
                    key={item}
                    onClick={() => {
                      this.setState({
                        pageSize: item,
                        paginationModalOpen: false,
                        pageNumber: 1
                      });
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={this.state.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() => this.closeModal())}
            className={styles.modalHeader}
          />
          <ModalBody>
            <p className='text-center'>
              <IoWarning className={styles.warningIcon} />
            </p>
            <h4 className={`second-headline-text ${styles.mainHeader}`}>Are you sure?</h4>
            <p className='text-center'>
              This will create a full-text index of all emails and attachments within your
              LegalHold.
            </p>
            <p className='text-center'>
              Depending on the data volume this can take some time to complete.
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className={`${styles.confirmBtn} btn secondary-btn mt-4 first-body-text me-3`}
                onClick={() => this.closeModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className={`${styles.confirmBtn} btn mt-4 first-body-text`}
                onClick={() => this.handleIndexing()}
              >
                Index
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeResetModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure?</p>
            <p className='text-center'>
              This will retry indexing any items that previously failed.
            </p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn mt-4 me-3 first-body-text'
                onClick={() => this.closeResetModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn mt-4 first-body-text'
                onClick={() => this.resetQueuedItems()}
              >
                Retry
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='lg'
          isOpen={this.props.openChatModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeChatModal())}>
            <p className='second-headline-text'>Create Legal Hold</p>
          </ModalHeader>
          <ModalBody>
            <LegalHoldSlackFormPage
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              currentUser={this.props.currentUser}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openDeleteModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openDeleteModal: false
              })
            )}
          >
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div className={styles.deleteModalContainer}>
              Are you sure you want to delete this item?
            </div>
            <div className={styles.deleteModalFooter}>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    openDeleteModal: false
                  })
                }
                className={`${styles.deleteButton} first-body-text btn secondary-btn`}
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => this.handleDeleteLegalHold()}
                className={`${styles.deleteButton} first-body-text btn danger-btn`}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    currentUser: store.auth.currentUser,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme,
    legalHoldStats: store.LegalHold.form.legalHoldStats,
    currentStep: store.LegalHold.form.currentStep,
    record: store.LegalHold.form.record,
    filters: store.LegalHold.form.filters,
    legalCase: store.LegalCase.form.record,
    createdLegalHoldId: store.LegalHold.form.legalHoldId,
    openChatModal: store.LegalHold.form.openChatModal,
    chatLegalHoldsList: store.LegalHold.list.chatLegalHoldsList,
    chatCount: store.LegalHold.list.chatCount
  };
}

export default connect(mapStateToProps)(ChatLegalHoldTilesView);
